/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSpring, animated } from "react-spring";
import "swiper/swiper-bundle.min.css";
import styled from 'styled-components';

const Wrapper = styled.section`
    display:-webkit-flex;
    overflow-x:scroll;
    margin:30px 0;
    padding-bottom:30px;
    -webkit-overflow-scrolling: touch;
    @media(min-width:768px){
        displa:flex;
        overflow:hidden;
      }
      img{
        width:60%;
        margin:0 auto;
        display:block;
        /* @media(min-width:640px){
          width:calc(100%/6);
        }
        @media(min-width:1024px){
          width:calc(100%/11);
        } */
      }
      .swiper-button-next,
  .swiper-button-prev{
    display:none;
    align-items:center;
    justify-content:center;
    height:50px;
      width:50px;
      background:rgba(255, 255, 255, 0.7);
      border-radius:50%;
    &:after{
      color:#000000;
      font-size:20px;
      
      
    }
  }
    /* img{
      width:40%;
      object-fit:contain;
      margin-right:8px;
      @media(min-width:768px){
        width:calc(100%/5);
      }
      &:last-child{
        margin-right:0;
      }
    } */
`

const flags = [
  {
    flag:'./images/africa/flag1.png',
    name:"angola flag",
  },
  {
    flag:'./images/africa/flag2.png',
    name:"algeria flag",
  },
  {
    flag:'./images/africa/flag3.png',
    name:"cameroon flag",
  },
  {
    flag:'./images/africa/flag4.png',
    name:"ethiopia flag",
  },
  {
    flag:'./images/africa/flag5.png',
    name:"senegal flag",
  },
  {
    flag:'./images/africa/flag6.png',
    name:"south african flag",
  },
  {
    flag:'./images/africa/flag7.png',
    name:"south sudan flag",
  },
  {
    flag:'./images/africa/flag9.png',
    name:"botswana flag",
  },
  {
    flag:'./images/africa/flag10.png',
    name:"democratic republic of congo flag",
  },
  {
    flag:'./images/africa/flag11.png',
    name:"egypt flag",
  },
  {
    flag:'./images/africa/flag12.png',
    name:"kenya flag",
  },
  {
    flag:'./images/africa/flag13.png',
    name:"malawi flag",
  },
  {
    flag:'./images/africa/flag14.png',
    name:"mali flag",
  },
  {
    flag:'./images/africa/flag15.png',
    name:"morocco flag",
  },
  {
    flag:'./images/africa/flag16.png',
    name:"rwanda flag",
  },
  {
    flag:'./images/africa/flag17.png',
    name:"uganda flag",
  },
  {
    flag:'./images/africa/flag18.png',
    name:"zambia flag",
  },

  {
    flag:'./images/africa/flag19.png',
    name:"togo flag",
  },
  {
    flag:'./images/africa/flag20.png',
    name:"tunisia flag",
  },
  {
    flag:'./images/africa/flag21.png',
    name:"zimbabwe flag",
  },
  {
    flag:'./images/africa/flag22.png',
    name:"mozambique flag",
  },
  {
    flag:'./images/africa/flag23.png',
    name:"namibia flag",
  },
  {
    flag:'./images/africa/flag24.png',
    name:"nigeria flag",
  },

    
  
]

SwiperCore.use([Navigation, Pagination, Autoplay]);

const swiperSettings = {
  slidesPerView: 3, // Show 1 slide by default
  spaceBetween:10,
  breakpoints: {
    // When window width is >= 640px (tablet)
    640: {
      slidesPerView: 6,
      spaceBetween: 20
    },
    // When window width is >= 1024px (laptop)
    1024: {
      slidesPerView: 11,
      spaceBetween: 30
    }
  }
};

function Flags() {
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (e) => {
    setSliderValue(Number(e.target.value));
  };

  const sliderSpring = useSpring({
    value: sliderValue,
    config: { mass: 1, tension: 300, friction: 30 },
  });
  return (
    <Wrapper>
     
       <Swiper
          
          autoplay={{ delay: 3000 }}
          pagination={false}
          loop={true}
          navigation
          onSlideChange={(swiper) => setSliderValue(swiper.activeIndex)}
          {...swiperSettings}
        >
           {flags.map((item, i)=>{
        return  <SwiperSlide>
        <div className="img-link">
        <img key={i} src={item.flag} alt={item.name} />
        </div>
      </SwiperSlide>
        
      })}
         
        </Swiper>
    </Wrapper>
  )
}

export default Flags