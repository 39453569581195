import React, { useState } from "react";
import { BsPlayCircle } from "react-icons/bs";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import styled from 'styled-components';
import Paragraph from "./Paragraph";

const Wrapper = styled.div`
display:none;
@media(min-width:768px){
  display:block;
}
.textvideo{
  position:absolute;
  right:0;
  bottom:0;
  color:#ffffff;
  background:#000000;
  display:flex;
  align-items:center;
  justify-content:center;
  height:130px;
  width:230px;
  svg{
    width:25px;
    height:25px;
    margin-right:20px;
  }
  p{
    font-size:12px;
    color:#ffffff;
    line-height:25px;
  }
}
`

function VideoPlayer() {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapper>
      <div onClick={() => setOpen(true)} className="textvideo">
        
      <BsPlayCircle />
        <Paragraph>Watch the 3D  <br />
Video of the museum</Paragraph>
      </div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="f7XBDtuYgn8" // replace with your video ID
        onClose={() => setOpen(false)}
      />
    </Wrapper>
  );
}

export default VideoPlayer;
