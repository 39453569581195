import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Wrapper = styled.h2`
   font-family:${props => props.theme.fam.bold};
   font-size:20px;
   line-height:35px;
   display:flex;
   flex-direction:column;

   img{
    width:100%;
    margin-top:10px;
    max-width:300px;
    object-fit:contain;
    @media(min-width:768px){
      max-width:400px;
      margin-top:20px;
    }
   }
`

function Heading({children}) {
  return (
    <Fade bottom>
    <Wrapper>
      {children}
      <img src="./images/under.png" alt="" />
    </Wrapper>
    </Fade>
  )
}

export default Heading
