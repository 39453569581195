import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import Paragraph from "../styled/Paragraph";
import { motion } from "framer-motion";

const Wrapper = styled.header`
    position:absolute;
    width:100%;
  background-color: #2DB34A;
  color: white;
  left:0;

transition:all 0.3s ease;
  .top-header{
    background:#C49801;
    padding:20px;
    transition:all 0.3s ease;
    font-family:${props => props.theme.fam.regular};
    display:flex;
    justify-content:center;
    flex-wrap:wrap;
    text-align:center;
    position:relative;
    z-index:9;
    p{
      text-transform:uppercase;
      font-size:16px;
      font-weight:500;
      margin-right:10px;
      line-height:1.3;
    }
    span{
      font-size:14px;
      
      font-weight:900;
      text-decoration:underline;
    }
  }

`

const WrapperInner = styled.header`
  position: sticky;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.4s ease;
  z-index: 99;
  background:#2DB34A;
  justify-content: space-between;
  padding:15px 0;
  
  @media (min-width: 768px) {
    
    top: 0;
    width: 90%;
    padding:15px 5%;
  }
  @media (min-width: 992px) {
    top: 20px;
  }

  img {
    width: 100px;
    z-index: 5;
    position: relative;
    margin-top: 11px;
    @media (min-width: 768px) {
      transform: translateX(-20%);
     
    }
    @media(min-width:992px){
      width:180px;
    }
  }
  ul {
    position: absolute;
    width: 100%;
    height: max-content;
    z-index: 4;
    background: #2DB34A;
    top: 100%;
    transition: all 0.7s ease;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    max-height:0;
    overflow: hidden;
    @media (min-width: 768px) {
      max-height: unset;
      flex-direction: row;
      background: transparent;
      height: max-content;
      align-items: center;
      justify-content: center;
      position: relative;
      transform: translateX(-4%);
    }
    @media (min-width: 992px) {
      transform: unset;
      justify-content: flex-end;
    margin-right: 100px;
    }
    li {
     
      a {
        padding: 20px;
        width: max-content;
        display: block;
        font-size: 15px;
        font-weight:700;
        transition: all 0.7s ease;
        text-transform: uppercase;
        color: #ffffff;
        font-family: ${(props) => props.theme.fam.regular};
        font-weight: 600;
        letter-spacing: 0.02em;
        @media (min-width: 768px) {
          font-size: 11px;
        }
        @media (min-width: 992px) {
          font-size: 16px;
        }
        &:hover {
          color: ${(props) => props.theme.color.blue};
        }
      }
      .active {
        color: #ffffff;

        position: relative;
        font-family:${props => props.theme.fam.semibold};
       &:before{
        position:absolute;
        height:2px;
        width:60%;
        background:#ffffff;
        bottom:10px;
        content:"";
        right:20%;
       }
        @media (min-width: 768px) {
          font-size: 12px;
          padding: 20px 0;
          
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            transition: all 0.7s ease;
            bottom: 30%;
            left: 50%;
            background: #ffffff;
            transform: translateX(-50%);
            width: calc(100% - 10px);
          }
        }
        @media (min-width: 992px) {
          font-size: 16px;
          padding: 20px;
          &:before {
            width: calc(100% - 40px);
            left: 50%;
            transform: translateX(-50%);
            bottom: 23%;
          }
        }
      }
    }
  }
  .openedMenuSmall {
    max-height: 400px;
  }
  .menu {
    display: none;
  }
  .hamburger.small {
    padding: 25px 42px 25px 8px;
    outline: 1px solid #ffffff;
    margin-right: 5%;
    z-index: 10;
    span {
      width: 35px;
      height: 1px;
      border-radius: 10px;
      transition: all 0.7s ease;
      background: #ffffff;
      position: absolute;
      &:before {
        width: 100%;
        height: 100%;
        background: #ffffff;
        position: absolute;
        transition: all 0.7s ease;
        content: "";
        top: -6px;
      }
      &:after {
        width: 100%;
        height: 100%;
        background: #ffffff;
        position: absolute;
        transition: all 0.7s ease;
        content: "";
        top: 6px;
      }
    }
  }
  .open.small {
    span {
      background: transparent;
      &:before {
        top: 0;
        transform: rotate(495deg);
        
      }
      &:after {
        top: 0;
        transform: rotate(-495deg);
      }
    }
  }
  .small {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .menu {
    display: none;
    @media (min-width: 768px) {
      display: block;
      background:transparent;
      .hamburger.small {
        display: block;
        margin-right: 5%;
      }
    }
    .hamburger.big {

      display: none;
      padding: 25px 42px 25px 8px;
      outline: 1px solid #ffffff;
      margin-right: 5%;
      z-index: 10;
      @media (min-width: 768px) {
        display: block;
        z-index: 10;
        position:relative;
      }
      span {
        width: 35px;
        height: 1px;
        border-radius: 10px;
        transition: all 0.7s ease;
        background: #ffffff;
        position: absolute;
        &:before {
          width: 100%;
          height: 100%;
          background: #ffffff;
          position: absolute;
          transition: all 0.7s ease;
          content: "";
          top: -6px;
        }
        &:after {
          width: 100%;
          height: 100%;
          background: #ffffff;
          position: absolute;
          transition: all 0.7s ease;
          content: "";
          top: 6px;
        }
      }
    }
    .big.open{
      outline-color:#2DB34A;
      span{
        &:after{
          background:#2DB34A;
          top:0;
          transform: rotate(495deg);
        }
        &:before{
          background:#2DB34A;
          top:0;
          transform: rotate(-495deg);
        }
      }
    }
    .menu-inner {
      display: none;
    }
    @media(min-width:768px){
        .menu-inner{
            display:block;
            height:100vh;
            width:max-content;
            position:absolute;
            background:#ffffff;
            right: 0%;
    top: -17%;
    transition:all 0.7s ease;
    overflow:hidden;
   
    z-index:6;
   
    padding-bottom:5%;
    max-width:0;
    h5{
         padding-top:200px;
    }
    h5,p{
        width:max-content;
    }
        }
        .menu-open{
            max-width:1000px;
            padding:5%;
        }
    }
  }
  .contact-link {
    a {
      cursor: pointer;
    }
  }
  h5 {
      font-family: ${(props) => props.theme.fam.regular};
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 20px;
      @media (min-width: 992px) {
        font-size: 25px;
      }
    }
    
`;

const SubItemVariants = {
  hidden: {
    x: -20,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const MenuItemVariants = {
  hidden: {
    x:0
  },
  visible: {
    x: 0,
    opacity:1,
    
    transition: {
      staggerChildren: 0.05,
    },
  },
};




const MyHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [smallClick, setSmallClick] = useState(false);

  return (
    <Wrapper>
      <div className="top-header">
      <p>“I am not African because I was born in Africa, but because Africa was born in me“</p>
      <span>Kwame Nkrumah</span>
    </div>
      <WrapperInner  className={isSticky ? "header sticky" : "header"}>
      <Link to="/">
        <img src="./images/logo.png" alt="Confide Pay Logo" />
      </Link>
      <div
        className={smallClick ? "hamburger small open" : "hamburger small"}
        onClick={() => setSmallClick(!smallClick)}
      >
        <span></span>
      </div>
      <motion.ul variants={MenuItemVariants} initial="hidden"
            animate="visible" className={smallClick ? "openedMenuSmall" : ""}>
        <motion.li layout
      variants={SubItemVariants}
       onClick={() => setSmallClick(false)}>
          <NavLink to="/" exact activeClassName="active">
            Home
          </NavLink>
        </motion.li>
        <motion.li layout
      variants={SubItemVariants} onClick={() => setSmallClick(false)}>
          <NavLink to="/about" activeClassName="active">
            About Us
          </NavLink>
        </motion.li>
        <motion.li layout
      variants={SubItemVariants} onClick={() => setSmallClick(false)}>
          <NavLink to="/news-and-events" activeClassName="active">
            News & Events
          </NavLink>
        </motion.li>
        <motion.li layout
      variants={SubItemVariants} onClick={() => setSmallClick(false)}>
          <NavLink to="/gallery" activeClassName="active">
            Gallery
          </NavLink>
        </motion.li>
        <motion.li layout
      variants={SubItemVariants} className="contact-link">
          <ScrollLink
            to="contact"
            activeClass="active"
            onClick={() => setSmallClick(false)}
            spy={true}
            smooth={true}
            duration={500}
          >
            Contact
          </ScrollLink>
        </motion.li>
      </motion.ul>
      <div className="menu">
        <div
          className={smallClick ? "hamburger big open" : "hamburger big"}
          onClick={() => setSmallClick(!smallClick)}
        >
          <span></span>
        </div>
        <div className={smallClick ? "menu-inner menu-open" : "menu-inner"}>
        <h5>Contact Details</h5>
          <Paragraph>
            <span>Address: <br /> </span>Cnr Samora Machel Ave <br /> West/GoldenQuarry Road, <br />
            Warren Park, Harare, Zimbabwe
          </Paragraph>
          <Paragraph>
            <span>Email address: <br /> </span>Info@africanliberation.museum
          </Paragraph>
        </div>
      </div>
    </WrapperInner>
    </Wrapper>
  );
};

export default MyHeader;