import React from "react";

import Banner from "../components/Banner";
import Intro from "../components/Home/Intro";
import Experience from "../components/Home/Experience";
import About from "../components/Home/About";
import Video from "../styled/Video";
  import Flags from "../components/Home/Flags";
import MyHeader from "../components/MyHeader";
import Where from "../components/Home/Where";
import Footer from "../components/Footer";
import News from "../components/Home/News";

function Home() {
  return (
    <>
     <MyHeader />
      <Banner />
      <Intro />
      <Experience />
      <Video />
      <About />
      <Flags />
      <News />
      <Where />
     
     <Footer />
    </>
  );
}

export default Home;
