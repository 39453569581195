import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
  padding: 5%;
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  h2 {
    margin-bottom: 30px;
    @media (min-width: 992px) {
      font-size: 35px;
    }
    img {
      width: 70%;
    }
  }
  h3 {
    margin-top: 30px;
    font-family: ${(props) => props.theme.fam.bold};
    font-size: 20px;
    margin-bottom: 20px;
  }
  .bottom{
    margin-top:40px;
    .bottom-inner{
      @media(min-width:768px){
        display:flex;
        flex-wrap:wrap;
        gap:50px;
        .item{
          width:45%;
          margin-bottom:20px;
        }
      }

      .item{
        &:nth-child(3){
          @media(min-width:768px){
            width:100%;
            
          }
        }
        @media(min-width:992px){
          width:max-content;
          min-width:30%;
        }
      }

      h3{
        font-family:${props => props.theme.fam.regular};
        margin-bottom:5px;
        font-weight:300;
        line-height:30px;
        @media(min-width:992px){
          font-size:30px;
        }
      }
      h5{
        font-family:${props => props.theme.fam.semibold};
        font-size:16px;
        letter-spacing:1px;
      }
    }
  }
`;



function KnowTheBackground() {
  return (
    <Wrapper>
      <Heading>Know the INSTAK background</Heading>
      <Paragraph>
        The Institute of African Knowledge (INSTAK) is a research think tank
        established in 2019 whose mandate is to research, gather and disseminate
        knowledge about Africa and African people. 
      </Paragraph>
      <Paragraph>
        {" "}
        INSTAK has been involved in many and varied projects in line with the
        organisation’s objective and such projects include the compilation and
        publication of the Africa Fact book and Book of African Records among
        other far-reaching legacy projects.
      </Paragraph>
      <Paragraph>
        Institute of African Knowledge is a trust registered with the Deeds
        Office of Zimbabwe, having been established to: be an agent of research,
        generation and dissemination of new African knowledge; research on all
        known disciplines of knowledge for the furtherance of information about
        Africa and the African people; raise awareness, publish, publicise and
        distribute whatever new knowledge and information that has been
        gathered; conduct capacity building programs through trainings,
        establishment of resource centres and participatory community-based
        activities for the appreciation, adoption and use of African knowledge
        and information; advocate for the adoption of African knowledge and
        information in curriculum content in institutions of primary, secondary,
        higher and tertiary learning across the African continent and beyond;
        organise special events, conferences, symposia, lectures, festivals and
        any other such undertakings that may help achieve the aforementioned
        objectives of the organisation. 
      </Paragraph>

    </Wrapper>
  );
}

export default KnowTheBackground;
