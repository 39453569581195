/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About";
import Home from "./pages/Home";
import ScrollToTop from "./ScrollTop";
import MyHeader from "./components/MyHeader";
import Where from "./components/Home/Where";
import Footer from "./components/Footer";
import Gallery from "./pages/Gallery";
import NewsEvents from "./pages/NewsEvents";
import ThankYou from "./components/ThankYou";
// import { FloatingWhatsApp } from 'react-floating-whatsapp'
// import ThankYou from "./components/ThankYou";

function App() {
  const body = document.body;
  let lastScroll = 0;
  
  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    const topHeader = document.querySelector(".top-header");
    if (currentScroll <= 0) {
      body.classList.remove("scroll-up");
      
      return;
    }
  
    if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-up");
      body.classList.add("scroll-down");
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains("scroll-down")
    ) {
      body.classList.remove("scroll-down");
      body.classList.add("scroll-up");
    }
    lastScroll = currentScroll;
  });
  
  return (
    <Router>
      <ScrollToTop>
      
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<NewsEvents />} path="/news-and-events" />
          <Route element={<Gallery />} path="/gallery" />
          <Route element={<ThankYou />} path="/submitted"/>
        </Routes>
         {/* <Follow /> */}
      
      </ScrollToTop>
      {/* <FloatingWhatsApp phoneNumber="+263783997491" accountName="Confide Pay" avatar={logo} /> */}
    </Router>
  );
}

export default App;