import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';



const MapContainer = (props) => {
  const { google } = props;

  return (
    <Map
      google={google}
      zoom={14}
      style={{zIndex:'1'}}
      initialCenter={{ lat: -17.823958, lng: 30.982654 }}
    >
      <Marker position={{ lat: -17.823958, lng: 30.982654 }} />
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB0OIv481IfOHVdi34gg2oNag5WKsR6FEQ',
})(MapContainer);

