import React from 'react';
import styled from 'styled-components';
import Heading from '../../styled/Heading';
import Paragraph from '../../styled/Paragraph';

const Wrapper = styled.section`
    background:#000000;
    padding:5%;
    margin-bottom:3px;
    @media(min-width:1200px){
        padding:5% 10%;
    }
    h2{
        color:#ffffff;
        margin-bottom: 30px;
    @media (min-width: 992px) {
      font-size: 35px;
    }
    img {
      width: 70%;
    }
    }
    .bottom-inner{
      @media(min-width:768px){
        display:flex;
        flex-wrap:wrap;
        gap:50px;
        .item{
          width:45%;
          margin-bottom:20px;
        }
      }

      .item{
        margin-bottom:30px;
        &:nth-child(3){
          @media(min-width:768px){
            width:100%;
            
          }
        }
        @media(min-width:992px){
          width:max-content;
          min-width:30%;
        }
      }

      h3{
        font-family:${props => props.theme.fam.regular};
        margin-bottom:5px;
        font-weight:300;
        line-height:30px;
        color:#ffffff;
        @media(min-width:992px){
          font-size:30px;
        }
      }
      h5{
        font-family:${props => props.theme.fam.semibold};
        font-size:14px;
        letter-spacing:1px;
        color:#ffffff;
        @media(min-width:992px){
            font-size:16px;
        }

      }
      p{
        color:#ffffff;
      }
    }

  
`

const board = [
    {
      name:'Prof. Justice. Simbi V Mubako',
      role:'Chairperson'
    },
    {
      name:'Ambassador Kwame T. Muzawazi.',
      role:'Administrative authority: Chief Executive Officer '
    },
    {
      name:'Liberation City, Cnr Samora Machel Ave West/Golden Quarry Road, Harare.',
      role:'Current Offices'
    },
    {
      name:'Museum of African Liberation',
      role:'Known Institutions'
    },
    {
      name:'The Africa Factbook and Book of African Records.',
      role:'Known Products'
    },
  ]

function Board() {
  return (
    <Wrapper>
 <Heading>Board of Trustees of 7 persons</Heading>
       
       <Paragraph>Highest authority: Board of Trustees of 7 persons</Paragraph>
        <div className="bottom-inner">
           {board.map((item, i)=>{
             return <div className="item" key={i}>
               <h3>{item.name}</h3>
               <h5>{item.role}</h5>
             </div>
           })}
       </div>
    </Wrapper>
  )
}

export default Board