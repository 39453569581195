import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.section`
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./images/news/news1.webp");
    background-size:cover;
    background-position:right center;
    padding:5%;
    height:300px;
    display:flex;
    flex-direction:column;
    gap:20px;
    justify-content: center;
    align-items: center;
    @media(min-width:992px){
        height:500px;
    }
    h2{
      
      font-family: ${(props) => props.theme.fam.bold};
      text-transform: uppercase;
      font-size: 30px;
      line-height:1.5;
      color:#ffffff;
      text-align:center;

      
      
      @media(min-width:768px){
        font-size:50px;
        margin-bottom:40px;
        
      }
    
    }
    a{
        height:50px;
        background:#ffffff;
        width:200px;
        font-size:14px;
        font-family:${props => props.theme.fam.regular};
        display:block;
        text-align: center;
        display: flex;
        align-items:center;
        justify-content:center;
        margin:0 auto;
        color:#000000;
    }
`

function News() {
  return (
    <Wrapper>
        <h2>SAMORA MACHEL MONUMENT UNVEILING</h2>
        <Link to="/news-and-events">Read about the events</Link>
    </Wrapper>
  )
}

export default News