import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";
// import { HOME_QUERY } from "../App";
// import { useQuery } from "@apollo/client";

const VideoWrapper = styled.div`
  height: 280px;

  @media (min-width: 768px) {
    height: 660px;
  }
  .ytp-swatch-background-color {
    /* background: #f6b119 !important; */
  }
  .ytp-cued-thumbnail-overlay-image {
    display: none !important;
  }
`;

function Video() {
  const youtubeConfig = {
    playerVars: {
      controls: 0,
      disablekb: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      iv_load_policy: 3,
      autohide: 1,
      // 1 - always show controls; 2 - hide controls on fullscreen; 3 - hide controls
      fs: 1,
    },
  };
  //   const {data} = useQuery(HOME_QUERY)
  return (
    <VideoWrapper>
      <ReactPlayer
        width="100%"
        height="100%"
        url="https://www.youtube.com/watch?v=f7XBDtuYgn8"
        controls={false}
        config={youtubeConfig}
      />
    </VideoWrapper>
  );
}

export default Video;
