import React from 'react'
import MyHeader from '../components/MyHeader'
import Footer from '../components/Footer';
import styled from 'styled-components';
import Paragraph from '../styled/Paragraph';
import NewsText from '../styled/NewsText';

const Wrapper = styled.section`
padding:5%;
padding-top:225px;

     @media(min-width:992px){
      .top{
        @media(min-width:992px){
        max-width: 900px;
        margin: 0 auto 100px;
    }
      }
     }
   h1 {
      font-family: ${(props) => props.theme.fam.bold};
      text-transform: uppercase;
      font-size: 40px;
      line-height:1.5;
      
      
      @media(min-width:768px){
        font-size:50px;
        margin-bottom:40px;
        
      }
    }
    .date{
      display:flex;
      gap:50px;
      margin-bottom:30px;
      @media(min-width:992px){
        gap:100px;
      }
    }
    .img{
      img{
        width:100%;
      }
    }
    .multi{
      display:flex;
      flex-direction: column;
      gap:20px;
      @media(min-width:768px){
        flex-direction:row;
        justify-content: space-between;
        gap:0;
        img{
          width:49%;
        }
      }
    }
`

function NewsEvents() {
  return (
    <>
     <MyHeader />
      <Wrapper>
        <div className="top">
           <h1>SAMORA MACHEL MONUMENT UNVEILING</h1>
        <div className="date">
          <Paragraph>31 May 2023</Paragraph>
          <Paragraph>Press Insert</Paragraph>
        </div>
        </div>
       
        <div className="img">
          <img src="./images/news/news1.webp" alt="" />
        </div>
        <NewsText desc="Mozambique Head of State President Filipe Nyusi and Zimbabwe Head of State President Emmerson Mnangagwa closely following proceedings during the Samora Machel Monument ground breaking ceremony at the Liberation City. " 
        heading="IN IT TOGETHER." />
        <div className="img multi">
          <img src="./images/news/news2.webp" alt="" />
          <img src="./images/news/news3.webp" alt="" />
        </div>
        <NewsText desc="President of Zimbabwe H.E Cde Dr Emmerson Mnangagwa delivers his speech during the ground breaking ceremony. He reiterated his call to African leaders to establish their respective national monuments at the Liberation City as Africa seeks to tell the African liberation story from an African perspective."
        heading="PRESIDENTIAL ADDRESS." />
        <div className="img multi">
          <img src="./images/news/news4.webp" alt="" />
          <img src="./images/news/news5.webp" alt="" />
        </div>
        <NewsText desc="Mozambique President H.E Filipe Nyusi symbolically hits the ground three times signalling the official commencement of Samora Machel Monument installation on a portion of land allocated to Mozambique at the Liberation City, Harare. President Emmerson Mnangagwa looks on and applauds his Mozambique brother."
        heading="HANDS-ON LEADERSHIP." />
        <div className="img multi">
          <img src="./images/news/news6.webp" alt="" />
          <img src="./images/news/news7.webp" alt="" />
        </div>
        <NewsText desc="Mozambique President Filipe Nyusi delivers his acceptance speech after the host country President H.E Cde Emmerson Mnangagwa had officially given Mozambique 1 acre of land for purposes of establishing the Samora Machel Monument at the Liberation City." 
        heading="OBRIGADO ZIMBABWE." />
        <div className="img">
          <img src="./images/news/news8.webp" alt="" />
        </div>
        <NewsText desc="Institute of African Knowledge (INSTAK) the administrators of the Museum of African Liberation gifted the Mozambique President Filipe Nyusi with a granite stone piece of art called Endless Connections, which aptly describes the historic and long standing relations between Zimbabwe and Mozambique."
        heading="ENDLESS CONNECTIONS GIFT." />
        <div className="img">
          <img src="./images/news/news9.webp" alt="" />
        </div>
        <NewsText desc="INSTAK Chairperson Ambassador Simbi Mubako delivers his remarks at the Samora Machel Monument ground breaking ceremony. He pledged his orgnisation’s commitment to ensuring Africa tells her story through various initiatives like establishing many other monuments by African countries at the Liberation City.  "
        heading="EXECUTIVE AFFIRMATIONS." />
        <div className="img multi">
          <img src="./images/news/news10.webp" alt="" />
          <img src="./images/news/news11.webp" alt="" />
        </div>
        <NewsText desc="INSTAK CEO Ambassador Kwame Muzawazi took the two Heads of State on the Museum of African Liberation construction site tour. He updated the national leaders on the progress made so far and explained the ongoing civil works at the site."
        heading="MUSEUM SUPER STRUCTURE SITE TOUR." />
        <div className="img">
          <img src="./images/news/news12.webp" alt="" />
        </div>
        <NewsText desc="Various stakeholders attended the Samora Machel Monument ground breaking ceremony and no better fitting stakeholder could witness the historic event than the Zimbabwe Defence Forces. Historically Mozambique haboured, protected and trained Zimbabwe liberation forces leading to the country’s independence in 1980."
         heading="STAKEHOLDER SOLIDARITY." />
        <div className="img">
          <img src="./images/news/news13.webp" alt="" />
        </div>
        <NewsText desc="Various African Diplomats were also on hand to witness the historic Samora Machel Monument ground breaking ceremony as an endorsement of their collective participation in the establishment of the Museum of African Liberation. African countries have been offered one acre portions of land to establish their respective national monuments at the Liberation City. Mozambique became the first African country to physically take up the offer."
        heading="YOUR COUNTRY IS NEXT." />
      </Wrapper>
    
     <Footer />
    </>
  )
}

export default NewsEvents