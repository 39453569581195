import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";

const Wrapper = styled.section`
  padding: 5%;
  overflow-x:hidden;
  @media(min-width:1200px){
    padding:5% 10%;
  
  }
  h2{
    font-size: 30px;
    margin-bottom:20px;
    @media(min-width:768px){
      text-align:center;
      margin:0 auto 20px;
      width:max-content;
    }
    
   @media(min-width:992px){
        font-size:40px;
        line-height:50px;
        padding-bottom:0;
        
      }
  }
  .inner{
    position:relative;
   
    h2{
      @media(min-width:768px){
      text-align:left;
      margin:0 0 20px;
      width:max-content;
    }
    }
    @media(min-width:992px){
      display:flex;
      justify-content:space-between;
      margin-top:122px;
      
    }
  }

  .inner-left,.inner-right{
    @media(min-width:768px){
      display:flex;
      justify-content:space-between;
      align-items:center;

      .img{
        width:45%;
        

      }
    }
    .img{
      @media(min-width:992px){
        width:100%;
        
      }
    }
    @media(min-width:992px){
        flex-direction:column;
        width:45%;
      }
    h2{
      text-align:left;
    }
    .inner-left-text,.inner-right-text{
      @media(min-width:768px){
        width:45%;
      }
      @media(min-width:992px){
        width:100%;
        
      }
    }
  }
  .inner-left-text{
    @media(min-width:992px){
      &:before{
        background:rgba(229, 229, 229, 0.5);
        height:630px;
        width:480px;
        content:"";
        position:absolute;
        left:-15%;
        z-index:-1;
        top:-100px;
      }
    }
 
     
    
  }
  p{
    margin-bottom:20px;
  }
  .inner-left-text{
    margin-top:20px;
  }
  .inner{
    .inner-right{
      @media(min-width:992px){
        justify-content:unset;
        margin-top:50px;
      }
      .inner-right-text{
        @media(min-width:992px){
          margin-bottom:50px;
          
        }

      }
      .img{
        @media(min-width:992px){
           position:relative;
          &:before{
            position:absolute;
            height:100%;
            width:100%;
            content:"";
            background:rgba(229, 229, 229, 0.5);
            top:28%;
            right:-28%;
            z-index:-1;
          }
        }
       
      }
    }
  }
`;

function Experience() {
  return (
    <Wrapper>
      <Heading>Experience Africa</Heading>
      <div className="inner">
  <div className="inner-left">
    <div className="img">
      <img src="./images/about.webp" alt="" />
    </div>
        
        <div className="inner-left-text">
          <Heading>Know the <br />
INSTAK background</Heading>
          <Paragraph>
            The establishment of the Museum of African Liberation is a priority
            project and the support of the Government of Zimbabwe, the host
            country, is a crucial first step. Following the signing of a
            Memorandum of Understanding (MOU) between the Government of Zimbabwe
            and INSTAK, the next concrete step towards the realization of the
            Museum project was the allocation and identification of a suitable
            site, a 100-hectare piece ofland, which His Excellency President
            Mnangagwa commissioned for this purpose at a ground breaking
            ceremony on 4th December 2020.{" "}
          </Paragraph>
        </div>
      </div>
      <div className="inner-right">
        <div className="inner-right-text">
        <Heading>About Museum of <br /> African Liberation</Heading>
        <Paragraph>
          The establishment of the Museum of African Liberation is a priority
          project and the support of the Government of Zimbabwe, the host
          country, is a crucial first step. Following the signing of a
          Memorandum of Understanding (MOU) between the Government of Zimbabwe
          and INSTAK, the next concrete step towards the realization of the
          Museum project was the allocation and identification of a suitable
          site, a 100-hectare piece ofland, which His Excellency President
          Mnangagwa commissioned for this purpose at a ground breaking ceremony
          on 4th December 2020.{" "}
        </Paragraph>
         </div>
         <div className="img">
           <img src="./images/know.webp" alt="" />
         </div>
       
     
        </div>
      </div>
    
     
    </Wrapper>
  );
}

export default Experience;
