import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalStyle from "./theme/GlobalStyle";
import { ThemeProvider } from "styled-components";
import './index.css'
import theme from "./theme/theme";
import './fonts/Oswald-Bold.ttf';
import './fonts/AvenirNext-Regular.ttf';
import './fonts/AvenirNext-Bold.ttf';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
    </ThemeProvider>
  </React.StrictMode>
);


