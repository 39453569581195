/* eslint-disable import/no-anonymous-default-export */
export default {
    fam:{
        regular:'AvenirNext-Regular',
        bold:'Oswald-Bold',
        semibold:'AvenirNext-Bold',
    },
    color:{
        red:"#DB2A27",
        
    },
}