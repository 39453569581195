import React from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import MapContainer from "../../styled/MapContainer";

const Wrapper = styled.section`
  padding:5%;
  @media(min-width:992px){
      display:flex;
      align-items:center
    }
    @media(min-width:1200px){
      padding:5% 10%;
    }
  h2{
    font-size:30px;
    position:relative;
    z-index:999;
    margin-bottom:20px;
    @media(min-width:992px){
      font-size:65px;
      width:max-content;
      line-height:70px;
      transform: translateX(150px);
    }
    @media(min-width:1200px){
      transform: translateX(30%);
    }
    img{
      @media(min-width:992px){
        max-width: 400px;
    margin-top: 20px;
    margin-right: 0;
    margin-left: auto;
      }
    }
  }
  .maps{
    width:100%;
    height:400px;
    margin-top:30px;
    max-width:350px;
    position:relative;
    margin:0 auto;
    margin-top:30px;
    z-index:3;
    @media(min-width:768px){
      width:575px;
      max-width:unset;
      height:720px;
    }
    #map-container {
  width: 100%;
  height: 100%;
  position:relative;
  z-index:1;
 
}

#map {
  width: 100%;
  height: 100%;
  z-index:-1;
  position:relative;
  z-index:1;
}
  }
  .inner{
    position:relative;
    z-index:4;
    @media(min-width:992px){
      width:45%;
      
      text-align:right;
      max-width:400px;
      margin-left:auto;
    }
  }
`;

function Where() {
  return (
    <Wrapper>
      <div className="inner">
        <Heading>Where is the <br /> Museum location</Heading>
        <Paragraph>
          The Museum of African Liberation is located at the Liberation City
          located in Harare, Zimbabwe. The expansive Liberation City is home to
          the Museum of African liberation, The Zimbabwe Defence Forces
          Exhibition Park, an animal and amusement park, the Heritage Village
          and many other commercial facilities designed to offer site visitors a
          holistic history, heritage, education, recreation, amusement and
          leisure experience at a single location.
        </Paragraph>
      </div>
      <div className="maps">
          <MapContainer />
      </div>
    </Wrapper>
  );
}

export default Where;
