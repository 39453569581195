/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { TextField } from "@mui/material";
import Paragraph from "../styled/Paragraph";

const Wrapper = styled.footer`
  padding:5%;
  background:#000000;
  color:#ffffff;
  @media(min-width:1200px){
    padding:5% 10%;
  }
  h2{
    font-family:${(props) => props.theme.fam.bold};
   font-size:30px;
   line-height:35px;
   @media(min-width:768px){
        font-size:45px;
        line-height:40px;
      }
   @media(min-width:992px){
        font-size:65px;
        line-height:70px;
        padding-bottom:0;
        
      }
   span{
    color:#C49801;
   }
  }
  form{
    margin-top:20px;
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    @media(min-width:768px){
      flex-wrap:wrap;
      flex-direction:row;
      justify-content:space-between;
    }
    .textbox1{
      @media(min-width:768px){
        width:48.5%;
      }
      @media(min-width:992px){
       width:31.5%;
      }
    }
   
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 15px;
      background:rgba(237, 237, 237, 0.2);
      height:200px;
      @media(min-width:768px){
        height:270px;
      }
      input, textarea{
        padding:15px 9% 0;
      }
      input,
      label, 
      textarea {
        color: #ffffff !important;
        font-family: ${(props) => props.theme.fam.regular} !important;
        font-size:16px;
        left: 9%;
        text-transform:uppercase;
    top: 11%;
      }
      #message-label{
        top:5%;
      }
      .MuiInputBase-root {
        &:before {
          border-bottom: none;
        }
        &:after{
          border:none;
        }
      }
      [data-shrink="true"] {
        /* color: ${(props) => props.theme.color.red} !important; */
        &:after {
          border-color: ${(props) => props.theme.color.red};
        }
      }
      [name="message"]{
        height:150px;
      }
    }
    .textbox1{
      .MuiFormControl-root {

        height:80px;
      }
      .MuiTextField-root{
      @media(min-width:768px){
        width:100%;
      }
    }
    }
    .MuiTextField-root{
      @media(min-width:768px){
        width:48.5%;
      }
      @media(min-width:992px){
        width:31.5%;
      }
    }
    button{
      
      font-weight:900;
      border:none;
      transition:all 0.7s ease-in-out;
      font-size:16px;
    font-family:{props => props.theme.fam.regular};
   
   span{
    z-index:4;
    position:relative;
    
   }
   @media(min-width:992px){
    order:2;
   }
    
    &:hover{
      /* box-shadow: inset 0 0 0 3em #000000; */
      cursor:pointer;
      background:#000000;
      span{
        color:#C49801;
        z-index:99;
      }
      
    }
   
}


    @media(min-width:992px){
        order:4;
      }
    }
   
    .address{
      background:rgba(237, 237, 237, 0.2);
      color:#ffffff;
      order:4;
      padding:20px;
      margin-top:20px;
      @media(min-width:992px){
        order:2;
        width:31.5%;
        margin-top:0;
        margin-bottom:15px;

      }
      h4{
        font-weight:900;
        font-size:25px;
        margin-bottom:15px;
        font-family:${props => props.theme.regular};
        text-transform:uppercase;
      }
      p{
        font-family:${props => props.theme.fam.regular};
        font-size:18px;
        line-height:1.3;
        margin-bottom:20px;
        display:flex;
        flex-wrap:wrap;
        span{
          font-weight:900;
          margin-right:10px;
        }
      }
    }
  }
  .bottom-footer{
    @media(min-width:768px){
      display:flex;
      justify-content:space-between;
      align-items:center;
    }
    h2{
      margin-top:20px;
      @media(min-width:768px){
        font-size:20px;
    }
    @media(min-width:992px){
      font-size:30px;
    }
    }
    p{
      color:#ffffff;
    }
    .right{
      display:flex;
      margin-top:30px;
      justify-content:center;
      @media(min-width:768px){
        width:50%;
      }
      @media(min-width:992px){
        width:30%;
      }
      .africa{
        width:30%;

      }
      .right-inner{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content:center;
        margin-left:20px;
        img{
          width:100%;
          object-fit:contain;
          margin-left:-15px;

        }
        p{
          margin-top:5px;
          a{
            color:#ffffff;
            transition:all 0.7s ease-in-out;
            &:hover{
              color:${props => props.theme.color.red};
            }
          }
        }
      }
    }
  }
`;

function Footer() {
  const year = moment().year();
  return (
    <Wrapper id="contact">
      <h2>
        Hello<span>;</span>
      </h2>
      <h2>Get in touch with us</h2>
      <form action="https://formsubmit.co/bc93fbb3430624d3ca71e95792739367" method="POST">
        <div className="textbox1">
          <TextField
            id="fullName"
            variant="standard"
            name="fullName"
            label="Name"
            required
          />
          <TextField
            id="phone"
            variant="standard"
            name="phone"
            label="Phone"
            required
          />
          <TextField
            id="email"
            variant="standard"
            name="email"
            label="Email Address"
            required
          />
        </div>

        <TextField
          id="message"
          name="message"
          variant="standard"
          label="Message..."
          multiline
          rows={3}
          required
        />
        <input
          type="hidden"
          name="_next"
          value="https://africanliberation.museum/submitted"
        />
        <input
          type="hidden"
          name="_subject"
          value="New submission from your website!"
        />
        <input type="text" name="_honey" style={{ display: "none" }} />
        <input type="hidden" name="_template" value="box" />
        <div className="address">
          <h4>Contact Details</h4>
          <p>
            <span>Address: </span>Cnr Samora Machel Ave West/GoldenQuarry Road,
            Warren Park, Harare, Zimbabwe
          </p>
          <p>
            <span>Email address: </span>Info@africanliberation.museum
          </p>
        </div>
        <button className="btn from-center">
          <span>Send Message</span>
        </button>
      </form>
      <hr />
      <div className="bottom-footer">
        <div className="left">
          <h2>Museum of African Liberation</h2>
          <Paragraph>©{year} Copyright. All Rights Reserved.</Paragraph>
        </div>
        <div className="right">
          <img className="africa" src="./images/mapofafrica.png" alt="Map Of Africa" />
          <div className="right-inner">
            <img src="./images/logo.png" alt="African Liberation Museum Logo" />
            <Paragraph>
              {" "}
              Built by <a href="#">Evoke Zw</a> & <a href="https://www.facebook.com/revixions">Revixions</a>
            </Paragraph>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Footer;
