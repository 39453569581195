/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import Heading from "../../styled/Heading";

const Wrapper = styled.section`
    padding:5%;
    @media(min-width:1200px){
        padding:5% 10%;


    }
    h2{
        font-size:25px;
        margin-bottom:30px;
        @media(min-width:992px){
            margin-left: 3.3%;
            font-size:45px;
            margin-bottom:50px;
        }
    }
    .inner{
        @media(min-width:768px){
           .row{
            display:flex;
            gap:10px;
            flex-wrap:wrap;
            justify-content:center;
           }
        }
        @media(min-width:1200px){
            
        }
        .row{
           img{
            object-fit:contain;
           }
                
            
            .first-b{
                display:flex;
                gap:10px;
            flex-wrap:wrap;
            justify-content:center;
            @media(min-width:768px){
                width:49%;
            }
            img{
                width:48%;
            }
            .first-ba{
                width:49%;
                
                img{
                    width:100%;
                }
            }
            }
            .second-b{
                @media(min-width:768px){
                    width:75%;
                }
                .second-ba{
                    @media(min-width:768px){
                    
                    display:flex;
                gap:10px;
            flex-wrap:wrap;
            justify-content:center;
            
                }
                img{
                    @media(min-width:768px){
                        width:66%;
                    }
                }
                    .colord{
                        position:relative;
                        @media(min-width:768px){
                            width:32%;
                        }
                        img{
                            width:100%;
                        }
                        .text{
                            position:absolute;
                            bottom:20%;
                            left:10%;
                            color:#ffffff;
                            h5{
                                font-size:20px;
                                margin-bottom:10px;
                                text-transform:uppercase;
                                @media(min-width:768px){
                                    font-size:13px;
                                }
                                @media(min-width:1200px){
                                    font-size:20px;
                                }
                            }
                            h3{
                                font-size:40px;
                                text-transform:uppercase;
                                @media(min-width:768px){
                                    font-size:20px;
                                }
                                @media(min-width:1200px){
                                    font-size:40px;
                                }
                            }
                        }
                    }
                }
                .second-bb{
                    @media(min-width:768px){
                    
                    display:flex;
                gap:10px;
            flex-wrap:wrap;
            justify-content:center;
                        img{
                            &:first-child{
                                @media(min-width:768px){
                        width:66%;
                    }
                            }
                            &:last-child{
                                @media(min-width:768px){
                            width:32%;
                        }
                            }
                        }
                }
                }
                .reverse{
                    @media(min-width:768px){
                    
                    display:flex;
                gap:10px;
            flex-wrap:wrap;
            justify-content:center;
                        img{
                            &:last-child{
                                @media(min-width:768px){
                        width:66%;
                    }
                            }
                            &:first-child{
                                @media(min-width:768px){
                            width:32%;
                        }
                            }
                        }
                }
                }
            }
        }
        .first{
            img{
                    @media(min-width:768px){
                        width:49%;
                    }
                }
            
        }
        .second{
            img{
                    @media(min-width:768px){
                        width:23%;
                    }
                }
        }
      
        img{
            width:100%;
            margin-bottom:10px;

            /* @media(min-width:768px){
                width:48%;
                margin-bottom:0;
            }
            @media(min-width:992px){
                width:30%;
            } */
        }
    }
    button{
      
      font-weight:900;
      border:none;
      transition:all 0.7s ease-in-out;
      font-size:16px;
    /* font-family:{props => props.theme.fam.regular}; */
   @media(min-width:992px){
    margin:20px auto;
   width:93.5%;
   }
   span{
    z-index:4;
    position:relative;
    
   }
   @media(min-width:992px){
    order:2;
   }
    
    &:hover{
      /* box-shadow: inset 0 0 0 3em #000000; */
      cursor:pointer;
      background:#000000;
      span{
        color:#C49801;
        z-index:99;
      }
      
    }
   
}

`;

const images = [
  {
    itemName: "Gallery 1",
    image: "./images/gallery1.webp",
  },
  {
    itemName: "Gallery 2",
    image: "./images/gallery2.png",
  },
  {
    itemName: "Gallery 3",
    image: "./images/gallery3.png",
  },
  {
    itemName: "Gallery 4",
    image: "./images/gallery4.png",
  },
  {
    itemName: "Gallery 5",
    image: "./images/gallery5.png",
  },
  {
    itemName: "Gallery 6",
    image: "./images/gallery6.png",
  },
  {
    itemName: "Gallery 7",
    image: "./images/gallery7.webp",
  },
  {
    itemName: "Gallery 8",
    image: "./images/gallery8.png",
  },
  {
    itemName: "Gallery 9",
    image: "./images/gallery9.png",
  },
  {
    itemName: "Gallery 10",
    image: "./images/gallery10.png",
  },
  {
    itemName: "Gallery 11",
    image: "./images/gallery11.png",
  },
  {
    itemName: "Gallery 12",
    image: "./images/gallery12.png",
  },
  {
    itemName: "Gallery 13",
    image: "./images/gallery13.webp",
  },

  {
    itemName: "Gallery 14",
    image: "./images/gallery14.png",
  },

  {
    itemName: "Gallery 15",
    image: "./images/gallery15.png",
  },

  {
    itemName: "Gallery 16",
    image: "./images/gallery16.png",
  },

  {
    itemName: "Gallery 17",
    image: "./images/gallery17.png",
  },

  {
    itemName: "Gallery 18",
    image: "./images/gallery18.png",
  },
];

function GallerySection() {
  const [visibleItems, setVisibleItems] = useState(9);

  const loadMore = () => {
    setVisibleItems((prevValue) => prevValue + 9);
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      loadMore();
    }
  };

  return (
    <Wrapper>
      <Heading>Our Gallery</Heading>
      {/* <div className='inner' onScroll={handleScroll}>
            {images.slice(0, visibleItems).map((item, i)=>{
                return  <img src={`./images/${item.image}`} key={i} alt={item.itemName} />
            })}
        </div> */}
      <div className="inner">
        <div className="row first">
          <img src={images[0].image} alt=" Gallery 1" />
          <div className="first-b">
            <img src={images[1].image} alt="Gallery 2" />
            <div className="first-ba">
              <img src={images[2].image} alt="Gallery 3" />
              <img src={images[3].image} alt="Gallery 4" />
            </div>
          </div>
        </div>
        <div className="row second">
          <img src={images[4].image} alt="Gallery 5" />
          <div className="second-b">
            <div className="second-ba">
              <div className="colord">
                <img src={images[5].image} alt="" />
                <div className="text">
                  <h5>Museum</h5>
                  <h3>Exhibition</h3>
                </div>
              </div>

              <img src={images[6].image} alt="" />
            </div>
            <div className="second-bb">
              <img src={images[7].image} alt="" />
              <img src={images[8].image} alt="" />
            </div>
          </div>
        </div>
        <div className="row first ">
          <div className="first-b">
            <div className="first-ba">
              <img src={images[9].image} alt="Gallery 3" />
              <img src={images[10].image} alt="Gallery 4" />
            </div>
            <img src={images[11].image} alt="Gallery 2" />
          </div>
          <img src={images[12].image} alt=" Gallery 1" />
        </div>
        <div className="row second reverse">
          <div className="second-b">
            <div className="second-ba">
              <img src={images[13].image} alt="" />
              <div className="colord">
                <img src={images[14].image} alt="" />
                <div className="text">
                  <h5>Museum</h5>
                  <h3>Exhibition</h3>
                </div>
              </div>
            </div>
            <div className="second-bb reverse">
              <img src={images[15].image} alt="" />
              <img src={images[16].image} alt="" />
            </div>
          </div>
          <img src={images[17].image} alt="Gallery 5" />
        </div>
      </div>
      {/* <button
        className={"btnn from-center"}
        onClick={() => loadMore()}
        style={visibleItems > 20 ? { display: "none" } : { display: "block" }}
      >
        <span>Click to Load More</span>
      </button> */}
    </Wrapper>
  );
}

export default GallerySection;
