import React from 'react';
import styled from 'styled-components';
import Paragraph from './Paragraph';

const Wrapper = styled.div`
    padding:30px 0;
    @media(media:768px){
        padding:5% 0;
    }
    @media(min-width:992px){
        max-width: 900px;
        margin: 5% auto;
    }
    span{
        margin-right:10px;
        display:block;
      font-family: ${(props) => props.theme.fam.semibold};
      text-transform: uppercase;
      font-size: 30px;
      line-height:1.5;
      @media(min-width:768px){
        font-size:40px;
       display:unset;

      }
    
    }
`

function NewsText({desc, heading}) {
  return (
    <Wrapper>
        
        
        <Paragraph><span>{heading}</span>{desc}</Paragraph>
    </Wrapper>
  )
}

export default NewsText