import React from 'react';
import Banner from '../components/About/Banner';
import Video from '../styled/Video';
import Our from '../components/About/Our';
import StoryLine from '../components/About/StoryLine';
import KnowTheBackground from '../components/About/KnowTheBackground';
import Board from '../components/About/Board';
import Flags from '../components/Home/Flags';
import MyHeader from '../components/MyHeader';
import Where from '../components/Home/Where';
import Footer from '../components/Footer';


function About() {
  return (
    <>
    <MyHeader />
     <Banner bgImage='about-bg.webp' /> 
     <Our />
     <Flags />
     <KnowTheBackground />
     <Board />
     <Video />
     <StoryLine />
     <Where />
     
     <Footer />
    </>
  )
}

export default About