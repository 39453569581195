import React from "react";
import styled from "styled-components";
import StoryLineDiv from "../../styled/StoryLineDiv";
import Heading from "../../styled/Heading";

const Wrapper = styled.section`
  padding: 5%;
  @media (min-width: 1200px) {
    padding: 5% 10%;
  }
  h2{
    
    text-align:center;
    margin:0 auto;
    margin-bottom:20px;
    text-align:center;
    align-items:center;
    font-size:25px;
    @media(min-width:768px){
        font-size:35px;
        margin-bottom:50px;
        width:max-content;
    }
  }
  .story-line-div {
    @media (min-width: 1300px) {
      &:first-child {
        .inner {
          height: 333px;
        }
      }
      &:nth-child(2) {
        .inner {
          height: 383px;
        }
      }
      &:nth-child(3) {
        .inner {
          height: 372px;
        }
      }
      &:last-child {
        .inner {
          height: 325px;
        }
      }
    }
  }
`;

function StoryLine() {
  return (
    <Wrapper>
      <Heading>INSTAK DIGITAL STORY LINE IN PICTURES:</Heading>
      <div className="inner-div">
        <StoryLineDiv
          textColor="#ffffff"
          direction="row"
          title="THE MUSEUM OF AFRICAN
        LIBERATION GROUND BREAKING CEREMONY"
          imageUrl="one.png"
          year="2019"
          text="His Excellency the President of the Republic of Zimbabwe Cde. Dr. Emmerson D. Mnangagwa energetically pounds the ground in a symbolic and historical gesture during the ground breaking ceremony at the Museum of African Liberation site in Warren Park, Harare on 3 December 2020. Looking on are the Minister of Home Affairs and Cultural Heritage Cde Kazembe Kazembe, INSTAK CEO Ambassador Kwame Muzawazi and INSTAK Board Chairperson Ambassador Prof. Simbi Mubako."
          bgColor="#0504AA"
        >
          THE MUSEUM OF AFRICAN LIBERATION GROUND BREAKING CEREMONY
        </StoryLineDiv>
        <StoryLineDiv
          textColor="#ffffff"
          direction="row-reverse"
          title="THE AFRICA
        FACTBOOK LAUNCH"
          imageUrl="two.png"
          year="2020"
          text="His Excellency the President of the Republic of Zimbabwe Cde. Dr. Emmerson D. Mnangagwa proudly displays a copy of The Africa Factbook he personally signed as an authentic and factual publication which demystifies some inaccurate narratives which had been peddled about Africa. Watching on are (from L to R) INSTAK CEO Amb. Kwame Muzawazi, INSTAK Board Chairperson Amb. Prof. Simbi Mubako and the African Union Chairperson and South African President, His Excellency Cyril Ramaphosa’s representative, the then South African Ambassador to Zimbabwe, His Excellency Mphakama Mbete. The Africa Factbook launch took place in Harare on the 9th of September 2020."
          bgColor="#2DB34A"
        >
          THE AFRICA FACTBOOK LAUNCH
        </StoryLineDiv>
        <StoryLineDiv
          textColor="#ffffff"
          direction="row"
          title="SOLIDARITY TREE PLANTING AND RUSSIA FEDERATION NATIONAL FLAG RAISING DAY"
          imageUrl="three.png"
          year="2021"
          text="The Ambassador of the Russian Federation Ambassador to Zimbabwe, Nikolai Krasilnikov plants a Mukumba tree at the Museum of African Liberation site in Warren Park, Harare. This was a symbolic gesture of solidarity between the Russian federation and the Museum of African Liberation. During the same event, on the 18th of February 2021, the Russian Diplomat also raised the Russian Federation flag to seal the relationship between the museum project and Russia. Looking on are the Cuban Ambassador to Zimbabwe Mrs Carmelina Ramirez Rodriguez and INSTAK CEO Amb. Kwame Muzawazi."
          bgColor="#C49801"
        >
          SOLIDARITY TREE PLANTING AND RUSSIA FEDERATION NATIONAL FLAG RAISING
          DAY
        </StoryLineDiv>
        <StoryLineDiv
          textColor="#ffffff"
          direction="row-reverse"
          title="CUBAN SOLIDARITY
        TREE PLANTING"
          imageUrl="four.png"
          year="2022"
          text="The Cuban Ambassador to Zimbabwe Mrs Carmelina Ramirez Rodriguez waters the Mukumba tree she had just planted at the Museum of African Liberation site under watchful eyes of the Russian Federation ambassador to Zimbabwe, Nikolai Krasilnikov and INSTAK CEO Amb. Kwame Muzawazi on 18 February 2021. She also took the opportunity to raise the Cuban national flag at the site symbolising Cuban support to the Museum of African Liberation."
          bgColor="#000000"
        >
          CUBAN SOLIDARITY TREE PLANTING
        </StoryLineDiv>
      </div>
    </Wrapper>
  );
}

export default StoryLine;
