/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSpring, animated } from "react-spring";
import "swiper/swiper-bundle.min.css";
import styled from "styled-components";
import Heading from "../../styled/Heading";
import Paragraph from "../../styled/Paragraph";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Wrapper = styled.section`
  padding: 5%;
  @media(min-width:992px){
    display:flex;
    justify-content:space-between;
    align-items:center;
  }
  .inner {
    @media(min-width:992px){
   width:45%;
  }
  @media(min-width:1200px){
   width:30%;
  }
    h2 {
      font-size: 30px;
      margin-bottom: 30px;
      @media(min-width:768px){
        font-size:30px;
      }
    }
    p {
      margin-bottom: 15px;
    }
    a {
      color: #000000;
      text-decoration: underline;
    }
  }
  .carousel{
    @media(min-width:992px){
    width:60%;
  }
  }
  .img-link{
    margin-top:30px;
  
    img{
      /* filter: drop-shadow(0px 35px 27px rgba(117, 113, 113, 0.3)); */
      width:100%;
    }
    
  }
  .swiper-button-next,
  .swiper-button-prev{
    display:flex;
    align-items:center;
    justify-content:center;
    height:50px;
      width:50px;
      background:rgba(255, 255, 255, 0.7);
      border-radius:50%;
    &:after{
      color:#000000;
      font-size:20px;
      
      
    }
  }
`;



function Intro() {
  const [sliderValue, setSliderValue] = useState(0);

  const handleSliderChange = (e) => {
    setSliderValue(Number(e.target.value));
  };

  const sliderSpring = useSpring({
    value: sliderValue,
    config: { mass: 1, tension: 300, friction: 30 },
  });
  return (
    <Wrapper>
      <div className="inner">
        <Heading>
          Introducing <br />
          Museum of Africa Liberation
        </Heading>
        <Paragraph>
          The Museum of African Liberation is one of the most ambitious projects
          by the emerging pan-African think tank - Institute of African
          Knowledge (INSTAK). Other great projects by INSTAK are The Africa
          Factbook in association with the African Union Commission and the
          sister publication Book of African Records.{" "}
        </Paragraph>
        <Link to="/about">Find out more</Link>
      </div>
      <div className="carousel">
        <Swiper
         slidesPerView={1}
          autoplay={{ delay: 3000 }}
          pagination={false}
          loop={true}
          navigation
          spaceBetween={30}
          onSlideChange={(swiper) => setSliderValue(swiper.activeIndex)}
          
        >
          <SwiperSlide>
            <div className="img-link">
              <img src="./images/carousel.webp" alt="Introduction to African Liberation Museum " />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="img-link">
              <img src="./images/carousel2.webp" alt="Introduction to African Liberation Museum 2" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </Wrapper>
  );
}

export default Intro;
