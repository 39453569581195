import React from 'react';
import styled from 'styled-components';
import Heading from '../../styled/Heading';
import Paragraph from '../../styled/Paragraph';

const Wrapper = styled.section`
    padding:5%;
    @media(min-width:992px){
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
    @media(min-width:1200px){
        padding:5% 10%;
    }
    .inner{
        @media(min-width:768px){
            margin-bottom:20px;
        }
        @media(min-width:992px){
                width:40%;
                margin-right:30px;
            }
        h2{
            font-size:25px;
            margin-bottom:30px;
            br{
                display:none;
            }
            @media(min-width:992px){
                font-size:45px;
                line-height:1.1;
                br{
                    display:block;
                }
            }
            @media(min-width:1200px){
                font-size:60px;
            }
            img{
                @media(min-width:992px){
                    width:70%;
                }
            }
        }
    }
    .right{
        @media(min-width:768px){
            position:relative;
            width:680px;
            display:flex;
            margin:0 auto;
        }
        img{
            display:none;
            @media(min-width:768px){
                display:block;
                width:500px;
                padding-bottom:140px;
            }
          
        }
    }
    .text{
        background:#0504AA;
        padding:30px 20px;
        color:#ffffff;
        margin-top:30px;
        @media(min-width:768px){
            position:absolute;
                bottom:0;
                right:0;
                width: calc(380px - 120px);
                padding:80px 60px 124px;
                border:17px solid #ffffff;
        }
        h4{
            /* font-family:${props => props.theme.fam.regular}; */
            font-size:25px;
            font-weight:900;
            position:relative;
            margin-bottom:30px;
            &:before{
                height:5px;
                width:137px;
                background:#ffffff;
                content:"";
                position:absolute;
                bottom:-15px;
                left:0;
            }
        }
        p{
            color:#ffffff;
            margin-bottom:20px;
        
        }
    }
`

function Our() {
  return (
    <Wrapper>
        <div className="inner">
            <Heading>Our <br /> People, <br />
Your  <br />
Africa</Heading>
<Paragraph>
The establishment of the Museum of African Liberation is a priority project and the support of the Government of Zimbabwe, the host country, is a crucial first step. Following the signing of a Memorandum of Understanding (MOU) between the Government of Zimbabwe and INSTAK, the next concrete step towards the realization of the Museum project was the allocation and identification of a suitable site, a 100-hectare piece of land, which His Excellency President Mnangagwa commissioned for this purpose at a groundbreaking ceremony on 4th December 2020.
</Paragraph>
        </div>
        <div className="right">
            <img src="./images/our.webp" alt="" />
            <div className="text">
                <h4>Our core values:</h4>
                <Paragraph>Innovative – Open and creative to customer and employee solutions.</Paragraph>
                <Paragraph>Transparent Communication and Collaboration. We communicate openly.</Paragraph>
                <Paragraph>Integrity - We deal with Clients in an above-board manner. Clear and concise communications is a core function.</Paragraph>
                <Paragraph>Teamwork - Our desire is to be an intregal part of your Team so that we "Exceed Your Expectations" at every turn.</Paragraph>
            </div>
        </div>
    </Wrapper>
  )
}

export default Our