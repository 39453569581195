/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import VideoPlayer from "../../styled/VideoPlayer";
import Paragraph from "../../styled/Paragraph";
import Typewriter from 'typewriter-effect';

const Wrapper = styled.section`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url("./images/${props => props.bgImage}");
  background-size: cover;
  background-position: center top;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  position: relative;
  @media(min-width:568px){
    height:660px;
  }
  @media(min-width:1200px){
    height:100vh;
  }
  .banner-inner {
    padding: 5%;
    padding-bottom: 30%;
    @media(min-width:768px){
      padding-bottom:5%;
      padding-right:0;
    }
    h1 {
      font-family: ${(props) => props.theme.fam.bold};
      color: #ffffff;
      text-transform: uppercase;
      font-size: 40px;
      line-height:1.5;
      br{
        display:none;
        @media(min-width:568px){
          display:block;
        }
      }
      @media(min-width:768px){
        font-size:45px;
        width:700px;
      }
    }
    p{
      color:#ffffff;
      margin-right:14px;
      
    }
    .Typewriter{
      color:#ffffff;
      font-size:20px;
      font-family:${props => props.theme.fam.regular};
      margin-bottom:20px;
      opacity:1;
    }
   
    .bottom{
      display:flex;
      flex-wrap:wrap;
      @media(min-width:768px){
      padding-top:25%;
    }
      a{
        border-radius:50%;
        border:1px solid rgba(255, 255, 255, 0.1);
        height:30px;
        width:30px;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-right:8px;
        svg{
          color:#ffffff;
        }
      }
    }
  }
`;

function Banner({bgImage}) {
  return (
    <Wrapper className="banner" bgImage={bgImage}>
      <div className="banner-inner">
        <h1>Welcome to the <br /> Museum of African Liberation</h1>
        <Typewriter
  options={{
    strings: ['We celebrates the rich history and diverse cultures of the African continent.', 'A testament to the struggles and triumphs of the African continent', 'Our exhibits showcase the struggles and triumphs of African people in their fight against oppression and injustice.', ''],
    autoStart: true,
    loop: true,
  }}
/>
        <div className="bottom">
          <Paragraph>Info@africanliberation.museum</Paragraph>

          <a href="#">
            <FaLinkedinIn />
          </a>
          <a href="#">
            <FaFacebookF />
          </a>
        </div>
      </div>
      <VideoPlayer />
    </Wrapper>
  );
}

export default Banner;

