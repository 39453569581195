import React from 'react';
import styled from 'styled-components';
import Paragraph from './Paragraph';
import { Fade } from 'react-reveal';

const Wrapper = styled.div`
@media(min-width:768px){
    display:flex;
flex-direction:${props => props.direction};
margin-bottom:30px;
align-items:center;
justify-content:space-between;
}

    img{
        width:100%;
        margin-bottom:20px;
        @media(min-width:768px){
            width:30%;
        }
        @media(min-width:992px){
            width:35%;
            margin-bottom:0;
        }
        @media(min-width:1200px){
            max-width:495px;

        }
       
    }
    .inner{
        padding:50px 20px 20px;
        padding-right: 35px;
        background:${props => props.bgColor};
        margin-bottom:20px;
        position:relative;

        @media(min-width:768px){
            width:59%;
            
        }
        @media(min-width:992px){
            width:47%;
            margin-bottom:0;
            padding:40px 120px 40px 63px; 
        }
        @media(min-width:1300px){
            max-width:680px;
            height:412px;
            max-width:680px;
            padding-bottom:0;
        }
        h3,p{
            color:${props => props.textColor};
        }
        p{
            @media(min-width:992px){
                font-size:18px;
            }
        }
        h3{
            font-family:${props => props.theme.fam.bold};
            font-size:23px;
            margin-bottom:15px;
            line-height:1.3;
        }
        span{
            font-size:50px;
            color:#ffffff;
            position:absolute;
            top:0;
            right:0;
            font-family:${props => props.theme.fam.bold};
            writing-mode: vertical-rl;
            text-orientation: mixed;
            transform: translate(4px, -5px) rotate(180deg);
            @media(min-width:768px){
                
                transform: translate(6px, -7px) rotate(180deg);
            }
            @media(min-width:992px){
                font-size:100px;
            }
        }
    }
`

function StoryLineDiv({textColor, direction, reverse, title, imageUrl, year, text, bgColor, children}) {
  return (
   
    <Wrapper className='story-line-div' textColor={textColor} direction={direction} reverse={reverse} bgColor={bgColor}>
     <Fade bottom>    <img src={`./images/${imageUrl}`} alt={title} />
        <div className="inner">
            <h3>{children}</h3>
            <Paragraph>{text}</Paragraph>
            <span>{year}</span>
        </div>
        </Fade>
    </Wrapper>
    
  )
}

export default StoryLineDiv;