import React from 'react';
import Banner from '../components/About/Banner';
import GallerySection from '../components/Gallery/GallerySection';
import MyHeader from '../components/MyHeader';
import Footer from '../components/Footer';

function Gallery() {
  return (
    <>
    <MyHeader />
        <Banner bgImage='about-bg.webp' /> 
        <GallerySection />
     <Footer />
    </>
  )
}

export default Gallery